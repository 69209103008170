<template>
  <div class="content-tab-poseur">
    <div class="box-content">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style"
            v-model="year"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <SelectComponent
            :options="ListWeek"
            :value="semaine"
            label="text"
            champName="semaine"
            filterName="0"
            :change="handleChangeSemaine"
            :track-by="'text'"
            :max-heigh="130"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style"
          />
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Poseur</span>
          </div>

          <SelectComponent
            :options="compputedListPoseur"
            :value="poseur"
            label="text"
            champName="poseur"
            filterName="poseur"
            :change="handleChangePoseur"
            :track-by="'text'"
            :max-heigh="130"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style w-1"
          />
        </div>
        <span v-if="getLoadingPoseur || loading" class="three-dots-loading">
          Chargement en cours
        </span>
        <div v-if="getErrorPoseur" class="error">
          <ul v-if="Array.isArray(getErrorPoseur)">
            <li v-for="(e, index) in getErrorPoseur" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getErrorPoseur }}</span>
        </div>

        <div class="box-end-position">
          <b-button
            v-if="poseur != null"
            size="sm"
            variant="light"
            class="button-default-style ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
      <div class="body-box-rapport">
        <div class="table-rapport-style w-100-p">
          <b-table
            v-if="computedRows && !getErrorPoseur"
            responsive
            show-empty
            id="my-table"
            class="custom-table-style tablePoseur"
            :items="computedRows"
            :fields="fields"
            sticky-header
            bordered
            hover
            head-variant="light"
            empty-text="Il n'y a aucun enregistrement à afficher"
            :tbody-tr-class="rowClass"
          >
            <template v-slot:head(anomalie)>
              <button
                ref="anomalie"
                class="btn"
                :class="{
                  desactive: ShowALL == false,
                  active: ShowALL == true
                }"
                @click="ShowALL ? hideAllDetails() : showAllDetails()"
              >
                <font-awesome-icon icon="bug" />
              </button>
            </template>

            <template v-slot:cell(anomalie)="data">
              <button
                class="btn"
                :class="{
                  desactive: data.detailsShowing == false,
                  active: data.detailsShowing == true
                }"
                @click="data.toggleDetails"
              >
                <font-awesome-icon icon="bug" />
              </button>
            </template>
            <template v-slot:cell(numero_dossier)="data">
              {{ data.item.numero_dossier }}
            </template>
            <template v-slot:cell(type_de_projet)="data">
              {{ data.item.type_de_projet }}
            </template>
            <template v-slot:cell(semaine)="data">
              {{ data.item.semaine }}
            </template>
            <template v-slot:cell(first_name)="data">
              <div :ref="'nom1' + data.index" :tabindex="-1">
                {{ data.item.nom1 }}
              </div>
            </template>
            <template v-slot:cell(last_name)="data">
              <div :ref="'prenom1' + data.index" :tabindex="-1">
                {{ data.item.prenom1 }}
              </div>
            </template>
            <template v-slot:cell(email)="data">
              <div :ref="'email' + data.index" :tabindex="-1">
                {{ data.item.email }}
              </div>
            </template>
            <template v-slot:cell(adresse)="data">
              <div :ref="'adresse' + data.index" :tabindex="-1">
                {{ data.item.adresse }}
              </div>
            </template>
            <template v-slot:cell(code_postal)="data">
              <div :ref="'code_postal' + data.index" :tabindex="-1">
                {{ data.item.code_postal }}
              </div>
            </template>
            <template v-slot:cell(departement)="data">
              <div :ref="'departement' + data.index" :tabindex="-1">
                {{ data.item.departement }}
              </div>
            </template>
            <template v-slot:cell(ville)="data">
              <div :ref="'ville' + data.index" :tabindex="-1">
                {{ data.item.ville }}
              </div>
            </template>
            <template v-slot:cell(mobile)="data">
              <div :ref="'mobile' + data.index" :tabindex="-1">
                {{ data.item.mobile }}
              </div>
            </template>
            <template v-slot:cell(fixe)="data">
              <div :ref="'fixe' + data.index" :tabindex="-1">
                {{ data.item.fixe }}
              </div>
            </template>
            <template v-slot:cell(etape)="data">
              <div :ref="'etape' + data.index" :tabindex="-1">
                {{ data.item.etape }}
              </div>
            </template>
            <template v-slot:cell(categorie)="data">
              <div :ref="'categorie' + data.index" :tabindex="-1">
                {{ data.item.categorie }}
              </div>
            </template>
            <template v-slot:cell(statut)="data">
              <div :ref="'statut' + data.index" :tabindex="-1">
                {{ data.item.statut }}
              </div>
            </template>
            <template v-slot:cell(organisme)="data">
              <div :ref="'organisme' + data.index" :tabindex="-1">
                {{ data.item.organisme }}
              </div>
            </template>
            <template v-slot:cell(regie)="data">
              <div :ref="'regie' + data.index" :tabindex="-1">
                {{ data.item.regie }}
              </div>
            </template>
            <template v-slot:cell(installeur_rge)="data">
              <div :ref="'installeur_rge' + data.index" :tabindex="-1">
                {{ data.item.installeur_rge }}
              </div>
            </template>
            <template v-slot:cell(previsiteur)="data">
              <div :ref="'previsiteur' + data.index" :tabindex="-1">
                {{ data.item.previsiteur }}
              </div>
            </template>
            <template v-slot:cell(confirmateur)="data">
              <div :ref="'confirmateur' + data.index" :tabindex="-1">
                {{ data.item.confirmateur }}
              </div>
            </template>
            <template v-slot:cell(administrateur)="data">
              <div :ref="'administrateur' + data.index" :tabindex="-1">
                {{ data.item.administrateur }}
              </div>
            </template>
            <template v-slot:cell(agent_commercial_terrain)="data">
              <div
                :ref="'agent_commercial_terrain' + data.index"
                :tabindex="-1"
              >
                {{ data.item.agent_commercial_terrain }}
              </div>
            </template>
            <template v-slot:cell(pose_date)="data">
              <div :ref="'pose_date' + data.index" :tabindex="-1">
                {{ data.item.pose_date }}
              </div>
            </template>
            <template v-slot:cell(pose_semaine)="data">
              <div :ref="'pose_semaine' + data.index" :tabindex="-1">
                {{ data.item.pose_semaine }}
              </div>
            </template>
            <template v-slot:cell(poseur)="data">
              <div :ref="'poseur' + data.index" :tabindex="-1">
                {{ data.item.poseur }}
              </div>
            </template>
            <template v-slot:cell(coef_ratio_prime)="data">
              <div :ref="'coef_ratio_prime' + data.index" :tabindex="-1">
                {{ data.item.coef_ratio_prime }}
              </div>
            </template>
            <template v-slot:cell(montant_101)="data">
              <div :ref="'montant_101' + data.index" :tabindex="-1">
                {{ data.item.montant_101 }}
              </div>
            </template>
            <template v-slot:cell(montant_102)="data">
              <div :ref="'montant_102' + data.index" :tabindex="-1">
                {{ data.item.montant_102 }}
              </div>
            </template>
            <template v-slot:cell(montant_103)="data">
              <div :ref="'montant_103' + data.index" :tabindex="-1">
                {{ data.item.montant_103 }}
              </div>
            </template>
            <template v-slot:cell(montant_total_ht)="data">
              <div :ref="'montant_total_ht' + data.index" :tabindex="-1">
                {{ data.item.montant_total_ht }}
              </div>
            </template>
            <template v-slot:cell(montant_total_ttc)="data">
              <div :ref="'montant_total_ttc' + data.index" :tabindex="-1">
                {{ data.item.montant_total_ttc }}
              </div>
            </template>
            <template v-slot:cell(surface_101)="data">
              <div :ref="'surface_101' + data.index" :tabindex="-1">
                {{ data.item.surface_101 }}
              </div>
            </template>
            <template v-slot:cell(surface_102)="data">
              <div :ref="'surface_102' + data.index" :tabindex="-1">
                {{ data.item.surface_102 }}
              </div>
            </template>
            <template v-slot:cell(surface_103)="data">
              <div :ref="'surface_103' + data.index" :tabindex="-1">
                {{ data.item.surface_103 }}
              </div>
            </template>
            <template v-slot:cell(surface_101_cp_deroule)="data">
              <div :ref="'surface_101_cp_deroule' + data.index" :tabindex="-1">
                {{ data.item.surface_101_cp_deroule }}
              </div>
            </template>
            <template v-slot:cell(surface_101_cp_souffle)="data">
              <div :ref="'surface_101_cp_souffle' + data.index" :tabindex="-1">
                {{ data.item.surface_101_cp_souffle }}
              </div>
            </template>
            <template v-slot:cell(surface_101_rampant)="data">
              <div :ref="'surface_101_rampant' + data.index" :tabindex="-1">
                {{ data.item.surface_101_rampant }}
              </div>
            </template>
            <template v-slot:cell(surface_102_murs)="data">
              <div :ref="'surface_102_murs' + data.index" :tabindex="-1">
                {{ data.item.surface_102_murs }}
              </div>
            </template>
            <template v-slot:cell(surface_102_pignons)="data">
              <div :ref="'surface_102_pignons' + data.index" :tabindex="-1">
                {{ data.item.surface_102_pignons }}
              </div>
            </template>
            <template v-slot:cell(surface_102_murs_iti)="data">
              <div :ref="'surface_102_murs_iti' + data.index" :tabindex="-1">
                {{ data.item.surface_102_murs_iti }}
              </div>
            </template>
            <template v-slot:cell(surface_102_murs_ite)="data">
              <div :ref="'surface_102_murs_ite' + data.index" :tabindex="-1">
                {{ data.item.surface_102_murs_ite }}
              </div>
            </template>
            <template v-slot:cell(surface_103_plafond_sous_sol)="data">
              <div
                :ref="'surface_103_plafond_sous_sol' + data.index"
                :tabindex="-1"
              >
                {{ data.item.surface_103_plafond_sous_sol }}
              </div>
            </template>
            <template v-slot:cell(surface_103_plafond_garage)="data">
              <div
                :ref="'surface_103_plafond_garage' + data.index"
                :tabindex="-1"
              >
                {{ data.item.surface_103_plafond_garage }}
              </div>
            </template>
            <template v-slot:cell(surface_103_plafond_cave)="data">
              <div
                :ref="'surface_103_plafond_cave' + data.index"
                :tabindex="-1"
              >
                {{ data.item.surface_103_plafond_cave }}
              </div>
            </template>
            <template v-slot:cell(surface_103_plafond_autres)="data">
              <div
                :ref="'surface_103_plafond_autres' + data.index"
                :tabindex="-1"
              >
                {{ data.item.surface_103_plafond_autres }}
              </div>
            </template>
            <template v-slot:cell(surface_103_plafonds)="data">
              <div :ref="'surface_103_plafonds' + data.index" :tabindex="-1">
                {{ data.item.surface_103_plafonds }}
              </div>
            </template>
            <template v-slot:cell(surface_103_vide_sanitaire)="data">
              <div
                :ref="'surface_103_vide_sanitaire' + data.index"
                :tabindex="-1"
              >
                {{ data.item.surface_103_vide_sanitaire }}
              </div>
            </template>

            <template v-slot:cell(total_surface_a_isoler)="data">
              <div :ref="'total_surface_a_isoler' + data.index" :tabindex="-1">
                {{ data.item.total_surface_a_isoler }}
              </div>
            </template>
            <template v-slot:cell(cumac_101)="data">
              <div :ref="'cumac_101' + data.index" :tabindex="-1">
                {{ data.item.cumac_101 }}
              </div>
            </template>
            <template v-slot:cell(cumac_102)="data">
              <div :ref="'cumac_102' + data.index" :tabindex="-1">
                {{ data.item.cumac_102 }}
              </div>
            </template>
            <template v-slot:cell(cumac_103)="data">
              <div :ref="'cumac_103' + data.index" :tabindex="-1">
                {{ data.item.cumac_103 }}
              </div>
            </template>
            <template v-slot:cell(kwh_cumac)="data">
              <div :ref="'kwh_cumac' + data.index" :tabindex="-1">
                {{ data.item.kwh_cumac }}
              </div>
            </template>
            <template v-slot:cell(total_prime_ttc_101)="data">
              <div :ref="'total_prime_ttc_101' + data.index" :tabindex="-1">
                {{ data.item.total_prime_ttc_101 }}
              </div>
            </template>
            <template v-slot:cell(total_prime_ttc_102)="data">
              <div :ref="'total_prime_ttc_102' + data.index" :tabindex="-1">
                {{ data.item.total_prime_ttc_102 }}
              </div>
            </template>
            <template v-slot:cell(total_prime_ttc_103)="data">
              <div :ref="'total_prime_ttc_103' + data.index" :tabindex="-1">
                {{ data.item.total_prime_ttc_103 }}
              </div>
            </template>
            <template v-slot:cell(total_prime_ttc)="data">
              <div :ref="'total_prime_ttc' + data.index" :tabindex="-1">
                {{ data.item.total_prime_ttc }}
              </div>
            </template>
            <template v-slot:cell(total_commission_tcc)="data">
              <div :ref="'total_commission_tcc' + data.index" :tabindex="-1">
                {{ data.item.total_commission_tcc }}
              </div>
            </template>
            <template v-slot:cell(precarite)="data">
              <div :ref="'precarite' + data.index" :tabindex="-1">
                {{ data.item.precarite }}
              </div>
            </template>
            <template v-slot:cell(type_chauffage)="data">
              <div :ref="'type_chauffage' + data.index" :tabindex="-1">
                {{ data.item.type_chauffage }}
              </div>
            </template>
            <template v-slot:cell(personnes_au_foyer)="data">
              <div :ref="'personnes_au_foyer' + data.index" :tabindex="-1">
                {{ data.item.personnes_au_foyer }}
              </div>
            </template>
            <template v-slot:cell(numero_facture)="data">
              <div :ref="'numero_facture' + data.index" :tabindex="-1">
                {{ data.item.numero_facture }}
              </div>
            </template>
            <template v-slot:cell(numero_lot)="data">
              <div :ref="'numero_lot' + data.index" :tabindex="-1">
                {{ data.item.numero_lot }}
              </div>
            </template>
            <template v-slot:cell(num_depot)="data">
              <div :ref="'num_depot' + data.index" :tabindex="-1">
                {{ data.item.num_depot }}
              </div>
            </template>
            <template v-slot:cell(date_devis)="data">
              <div :ref="'date_devis' + data.index" :tabindex="-1">
                {{ data.item.date_devis }}
              </div>
            </template>
            <template v-slot:cell(date_facture)="data">
              <div :ref="'date_facture' + data.index" :tabindex="-1">
                {{ data.item.date_facture }}
              </div>
            </template>
            <template v-slot:cell(coposeurs)="data">
              <div :ref="'coposeurs' + data.index" :tabindex="-1">
                {{ data.item.coposeurs }}
              </div>
            </template>
            <template v-slot:cell(revenu_fiscal_ref)="data">
              <div :ref="'revenu_fiscal_ref' + data.index" :tabindex="-1">
                {{ data.item.revenu_fiscal_ref }}
              </div>
            </template>
            <template v-slot:cell(date_depot_dossier)="data">
              <div :ref="'date_depot_dossier' + data.index" :tabindex="-1">
                {{ data.item.date_depot_dossier }}
              </div>
            </template>
            <template v-slot:cell(societe_poseur)="data">
              <div :ref="'societe_poseur' + data.index" :tabindex="-1">
                {{ data.item.societe_poseur }}
              </div>
            </template>
            <template v-slot:cell(responsable_zone)="data">
              <div :ref="'responsable_zone' + data.index" :tabindex="-1">
                {{ data.item.responsable_zone }}
              </div>
            </template>
            <template v-slot:cell(depot_stockage)="data">
              <div :ref="'depot_stockage' + data.index" :tabindex="-1">
                {{ data.item.depot_stockage }}
              </div>
            </template>
            <template v-slot:cell(source)="data">
              <div :ref="'source' + data.index" :tabindex="-1">
                {{ data.item.source }}
              </div>
            </template>
            <template v-slot:cell(pipedrive_lead_id)="data">
              <div :ref="'pipedrive_lead_id' + data.index" :tabindex="-1">
                {{ data.item.pipedrive_lead_id }}
              </div>
            </template>
            <template v-slot:cell(date_creation_fiche)="data">
              <div :ref="'date_creation_fiche' + data.index" :tabindex="-1">
                {{ data.item.date_creation_fiche }}
              </div>
            </template>
            <template v-slot:cell(type_lead)="data">
              <div :ref="'type_lead' + data.index" :tabindex="-1">
                {{ data.item.type_lead }}
              </div>
            </template>
            <template v-slot:cell(commentaire)="data">
              <div :ref="'commentaire' + data.index" :tabindex="-1">
                {{ data.item.commentaire }}
              </div>
            </template>
            <template v-slot:cell(zone_climatique)="data">
              <div :ref="'zone_climatique' + data.index" :tabindex="-1">
                {{ data.item.zone_climatique }}
              </div>
            </template>
            <template v-slot:cell(montant_operation)="data">
              <div :ref="'montant_operation' + data.index" :tabindex="-1">
                {{ data.item.montant_operation }}
              </div>
            </template>
            <template v-slot:cell(created_at)="data">
              <div :ref="'created_at' + data.index" :tabindex="-1">
                {{ data.item.created_at }}
              </div>
            </template>
            <template v-slot:cell(updated_at)="data">
              <div :ref="'updated_at' + data.index" :tabindex="-1">
                {{ data.item.updated_at }}
              </div>
            </template>
            <template v-slot:cell(previsite_date)="data">
              <div :ref="'previsite_date' + data.index" :tabindex="-1">
                {{ data.item.previsite_date }}
              </div>
            </template>
            <template #row-details="data">
              <b-card class="cardAnomaliePoseur" v-if="data.item.anomalies">
                <p><strong>Anomalie </strong></p>
                <b-row
                  class="mb-2"
                  v-for="anomalie in data.item.anomalies"
                  :key="anomalie.id"
                >
                  <div
                    class="anomalie"
                    @click="focus(anomalie.name + data.index)"
                  >
                    <b>{{ anomalie.name | MessageFormat }} </b>
                  </div>
                  <div class="anomalie">{{ anomalie.message }}</div>
                </b-row>
              </b-card>
              <b-card class="classAlertPoseur" v-if="data.item.alerts">
                <p><strong>Alerts</strong></p>
                <b-row
                  class="mb-2"
                  v-for="alert in data.item.alerts"
                  :key="alert.id"
                >
                  <div class="anomalie" @click="focus(alert.name + data.index)">
                    <b>{{ alert.name | MessageFormat }} </b>
                  </div>
                  <div class="anomalie">{{ alert.message }}</div>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      semaine: null,
      poseur: null,
      year: null,
      ListYear: [],
      ListWeek: [],
      ShowALL: false,
      loading: false,
      fields: [
        {
          key: 'anomalie',
          label: 'Anomalie',
          thClass: 'th-anomalie',
          tdClass: 'col-anomalie'
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          thClass: 'colhead-num-dossier',
          tdClass: 'col-num-dossier'
        },
        { key: 'type_de_projet', label: 'Type de projet' },
        { key: 'last_name', label: 'Prenom' },
        { key: 'first_name', label: 'Nom' },
        { key: 'email', label: 'Email' },
        { key: 'semaine', label: 'Semaine' },
        { key: 'adresse', label: 'Adresse' },
        { key: 'code_postal', label: 'Code Postal' },
        { key: 'departement', label: 'Departement' },
        { key: 'ville', label: 'Ville' },
        { key: 'mobile', label: 'Mobile' },
        { key: 'fixe', label: 'Fixe' },
        { key: 'etape', label: 'Etape' },
        { key: 'categorie', label: 'Categorie' },
        { key: 'statut', label: 'Statut' },
        { key: 'organisme', label: 'Organisme' },
        { key: 'regie', label: 'Regie' },
        { key: 'installeur_rge', label: 'Installeur rge' },
        {
          key: 'previsiteur',
          label: 'Pré-visiteur'
        },
        { key: 'confirmateur', label: 'Confirmateur' },
        { key: 'agent_commercial_terrain', label: 'Agent commercial terrain' },
        { key: 'administrateur', label: 'Administrateur' },
        { key: 'pose_date', label: 'Pose date' },
        { key: 'pose_semaine', label: 'Pose semaine' },
        { key: 'poseur', label: 'Poseur' },
        { key: 'coef_ratio_prime', label: 'Coef ratio prime' },
        { key: 'montant_101', label: 'Montant 101' },
        { key: 'montant_102', label: 'Montant 102' },
        { key: 'montant_103', label: 'Montant 103' },
        { key: 'montant_total_ht', label: 'Montant total ht' },
        {
          key: 'montant_total_ttc',
          label: 'Montant total ttc'
        },
        {
          key: 'surface_101_cp_deroule',
          label: 'Surface 101 cp deroule'
        },
        {
          key: 'surface_101_cp_souffle',
          label: 'Surface 101 cp souffle'
        },
        {
          key: 'surface_101_rampant',
          label: 'Surface 101 rampant'
        },
        { key: 'surface_101', label: 'Surface 101' },
        { key: 'surface_102_murs', label: 'Surface 102 murs' },
        {
          key: 'surface_102_pignons',
          label: 'Surface 102 pignons'
        },
        {
          key: 'surface_102_murs_iti',
          label: 'Surface 102 murs_iti'
        },
        {
          key: 'surface_102_murs_ite',
          label: 'Surface 102 murs ite'
        },
        { key: 'surface_102', label: 'Surface 102' },
        {
          key: 'surface_103_plafond_sous_sol',
          label: 'Surface 103 Plafond Sous-sol'
        },
        {
          key: 'surface_103_plafond_garage',
          label: 'Surface 103 Plafond Garage'
        },
        {
          key: 'surface_103_plafond_cave',
          label: 'Surface 103 Plafond Cave'
        },
        {
          key: 'surface_103_plafond_autres',
          label: 'Surface 103 Plafond autres'
        },
        {
          key: 'surface_103_plafonds',
          label: 'Surface 103 plafonds'
        },
        {
          key: 'surface_103_vide_sanitaire',
          label: 'Surface 103 vide sanitaire'
        },
        { key: 'surface_103', label: 'Surface 103' },
        {
          key: 'total_surface_a_isoler',
          label: 'Total surface a isoler'
        },
        { key: 'cumac_101', label: 'Cumac 101' },
        { key: 'cumac_102', label: 'Cumac 102' },
        { key: 'cumac_103', label: 'Cumac 103' },
        { key: 'kwh_cumac', label: 'Kwh cumac' },
        { key: 'total_prime_ttc_101', label: 'total prime ttc 101' },
        { key: 'total_prime_ttc_102', label: 'total prime ttc 102' },
        { key: 'total_prime_ttc_103', label: 'total prime ttc 103' },
        { key: 'total_prime_ttc', label: 'total prime ttc' },
        { key: 'total_commission_tcc', label: 'total commission ttc' },
        { key: 'precarite', label: 'Precarite' },
        { key: 'type_chauffage', label: 'Type chauffage' },
        {
          key: 'personnes_au_foyer',
          label: 'Personnes au foyer'
        },
        { key: 'numero_facture', label: 'Numero facture' },
        { key: 'numero_lot', label: 'Numero lot' },
        { key: 'num_depot', label: 'Num depot' },
        { key: 'date_devis', label: 'Date devis' },
        { key: 'date_facture', label: 'Date facture' },
        { key: 'coposeurs', label: 'Coposeurs' },
        {
          key: 'revenu_fiscal_ref',
          label: 'Revenu fiscal ref'
        },
        {
          key: 'date_depot_dossier',
          label: 'Date depot dossier'
        },
        { key: 'societe_poseur', label: 'Societe poseur' },
        { key: 'responsable_zone', label: 'Responsable zone' },
        { key: 'depot_stockage', label: 'Depot stockage' },
        { key: 'source', label: 'Source' },
        { key: 'pipedrive_lead_id', label: 'Pipedrive lead id' },

        {
          key: 'date_creation_fiche',
          label: 'Date creation fiche'
        },
        { key: 'type_lead', label: 'Type lead' },
        { key: 'commentaire', label: 'Commentaire' },
        { key: 'zone_climatique', label: 'Zone climatique' },
        {
          key: 'montant_operation',
          label: 'Montant operation'
        },
        { key: 'created_at', label: 'Created at' },
        { key: 'updated_at', label: 'Updated at' },
        { key: 'previsite_date', label: 'Prévisite date' }
      ]
    };
  },
  methods: {
    ...mapActions([
      'fetchRapportDataPoseur',
      'exportFilePoseur',
      'fetchPoseurActive',
      'resetTablePoseur'
    ]),
    focus(key) {
      this.$refs[key].focus({ preventScroll: false });
    },
    async handleChangeYear() {
      this.page = 1;
      var totalWeek = moment({ year: this.year }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: 's' + week });
      }
      const response = await this.fetchPoseurActive({
        semaine: this.semaine,
        year: this.year
      });

      if (response) {
        this.fetchDataRapport();
      }
    },
    async handleChangeSemaine(payload) {
      this.page = 1;
      if (payload) {
        this.semaine = { value: payload.value.value, text: payload.value.text };
      }
      const response = await this.fetchPoseurActive({
        semaine: this.semaine,
        year: this.year
      });
      if (response) {
        this.fetchDataRapport();
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.anomalies) return 'ligneAnomalie';
      else return 'ligneAlert';
    },
    async exportFiles() {
      await this.exportFilePoseur({
        semaine: this.semaine,
        annee: this.year,
        poseur: this.poseur
      });
    },
    showAllDetails() {
      this.computedRows.forEach(item => {
        this.$set(item, '_showDetails', true);
      });
      this.ShowALL = !this.ShowALL;
    },
    hideAllDetails() {
      this.computedRows.forEach(item => {
        this.$set(item, '_showDetails', false);
      });
      this.ShowALL = !this.ShowALL;
    },
    handleChangePoseur(payload) {
      this.page = 1;
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.fetchRapportDataPoseur({
        page: this.page,
        per_page: this.perPage,
        semaine: this.semaine,
        annee: this.year,
        poseur: this.poseur
      });
    },
    fetchDataRapport() {
      if (this.getPoseurActive.length > 0) {
        this.poseur = {
          text: this.getPoseurActive[0].full_name,
          value: this.getPoseurActive[0].id
        };
      } else {
        this.poseur = null;
      }
      this.fetchRapportDataPoseur({
        page: this.page,
        per_page: this.perPage,
        semaine: this.semaine,
        annee: this.year,
        poseur: this.poseur
      });
    }
  },
  filters: {
    MessageFormat: value => {
      switch (value) {
        case 'prenom1':
          return 'prenom';
        case 'first_name':
          return 'nom';
        case 'numero_dossier':
          return 'numero de dossier';
        case 'code_postal':
          return 'code postal';
        case 'pose_date':
          return 'pose date';
        case 'pose_semaine':
          return 'pose semaine';
        case 'coef_ratio_prime':
          return 'coef ratio prime';
        case 'montant_101':
          return 'montant 101';
        case 'montant_102':
          return 'montant 102';
        case 'montant_103':
          return 'montant 103';
        case 'montant_total_ht':
          return 'montant total ht';
        case 'montant_total_ttc':
          return 'montant total ttc';
        case 'surface_101_cp_deroule':
          return 'surface 101 cp deroule';
        case 'surface_101_cp_souffle':
          return 'surface 101 cp souffle';
        case 'surface_101_rampant':
          return 'surface 101 rampant';
        case 'surface_101':
          return 'surface 101';
        case 'surface_102_murs':
          return 'surface 102 murs';
        case 'surface_102_pignons':
          return 'surface 102 pignons';
        case 'surface_102_murs_iti':
          return 'surface 102 murs iti';
        case 'surface_102_murs_ite':
          return 'surface 102 murs ite';
        case 'surface_102':
          return 'surface 102';
        case 'surface_103_plafonds':
          return 'surface 103 plafonds';
        case 'surface_103_vide_sanitaire':
          return 'surface 103 vide sanitaire';
        case 'surface_103':
          return 'surface 103';
        case 'total_surface_a_isoler':
          return 'Total surface a isoler';
        case 'cumac_101':
          return 'cumac 101';
        case 'cumac_102':
          return 'cumac 102';
        case 'cumac_103':
          return 'cumac 103';
        case 'kwh_cumac':
          return 'kwh cumac';
        case 'type_chauffage':
          return 'type chauffage';
        case 'personnes_au_foyer':
          return 'personnes au foyer';
        case 'numero_facture':
          return 'numero facture';
        case 'numero_lot':
          return 'numero lot';
        case 'num_depot':
          return 'numero depot';
        case 'date_devis':
          return 'date devis';
        case 'date_facture':
          return 'date facture';
        case 'revenu_fiscal_ref':
          return 'revenu fiscal ref';
        case 'date_depot_dossier':
          return 'date depot dossier';
        case 'societe_poseur':
          return 'societe poseur';
        case 'responsable_zone':
          return 'responsable zone';
        case 'depot_stockage':
          return 'depot stockage';
        case 'pipedrive_lead_id':
          return 'pipedrive lead id';
        case 'date_creation_fiche':
          return 'date creation fiche';
        case 'type_lead':
          return 'type lead';
        case 'zone_climatique':
          return 'zone climatique';
        case 'montant_operation':
          return 'montant operation';
        case 'created_at':
          return 'created at';
        case 'updated_at':
          return 'updated at';
        case 'previsiteur':
          return 'previsiteur at';
        case 'installeur_rge':
          return 'installeur rge';
        case 'agent_commercial_terrain':
          return 'Agent commercial terrain';
        default:
          return value;
      }
    }
  },
  computed: {
    ...mapGetters([
      'getDataPoseur',
      'getTotalRowsPoseur',
      'getErrorPoseur',
      'getLoadingPoseur',
      'getPoseurActive'
    ]),
    computedRows() {
      if (!this.getDataPoseur) {
        return [];
      }
      return this.getDataPoseur;
    },

    compputedListPoseur() {
      return this.getPoseurActive.map(item => {
        return {
          text: item.full_name,
          value: item.id
        };
      });
    }
  },
  async mounted() {
    this.loading = true;
    var courantYear = moment().year();
    this.year = courantYear;
    const weekCourant = moment().isoWeek();
    this.semaine = { value: 's' + weekCourant, text: 's' + weekCourant };
    var totalWeek = moment({ year: this.year }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: 's' + week });
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    const response = await this.fetchPoseurActive({
      semaine: this.semaine,
      year: this.year
    });
    if (response) {
      if (this.getPoseurActive.length > 0) {
        this.fetchDataRapport();
      } else {
        this.resetTablePoseur();
      }
    }
    this.loading = false;
  },
  components: {
    SelectComponent: () => import('./SelectComponent.vue')
  }
};
</script>
<style scoped lang="scss">
.tablePoseur {
  max-height: 100% !important;
  height: 100% !important;
}
.content-tab-poseur {
  margin: 10px;
  width: 100%;
  display: inline;
  height: calc(100vh - 60px);
  overflow: hidden;
  .box-content {
    width: 100%;
    .desactive {
      color: #c5c5c5;
    }
    .active {
      color: #8d8cb7;
    }
    .error {
      font-size: 13px;
      padding: 10px 20px;
      color: #ff6961;
    }
    .cardAnomaliePoseur {
      position: relative;
      background-color: #ededed !important;
      color: #858283;
      p {
        color: #fba5b3;
        margin-bottom: 0px;
        font-size: 14px;
        width: 78px;
      }
    }
    .classAlertPoseur {
      position: relative;
      background-color: #ededed !important;
      color: #858283;
      p {
        color: #e8c559;
        margin-bottom: 0px;
        font-size: 14px;
        width: 78px;
      }
    }
  }
}
</style>

<style lang="scss">
.cardAnomaliePoseur .card-body {
  width: 800px !important;
  left: 21px;
}
.classAlertPoseur .card-body {
  width: 800px !important;
  left: 21px;
}
</style>
